import * as React from "react";
import { graphql } from "gatsby";

import { PortableText, ProtectedImage, Layout, PageHeading } from "components";

function AboutPage({
  data: {
    sanityAboutPage: { content, image },
  },
}) {
  return (
    <Layout pageTitle="About">
      <PageHeading heading="About" />

      {image?.asset?.gatsbyImageData && (
        <ProtectedImage
          loading="eager"
          image={image.asset.gatsbyImageData}
          alt="Austin / Desmond Fine Art"
          sx={{
            ml: [null, "xl"],
            mb: [null, "l"],
            float: [null, "right"],
            width: [null, 400, 600],
          }}
        />
      )}

      {content && <PortableText content={content} />}
    </Layout>
  );
}

export const query = graphql`
  query AboutPage {
    sanityAboutPage(_id: { eq: "about_page" }) {
      image {
        asset {
          gatsbyImageData(width: 686)
        }
      }
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default AboutPage;
